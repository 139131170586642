import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Bolby",
  avatarImage: "/images/selfie2.jpg",
  content:
    "I am Kaan Taze, software engineer from Adana, Turkey. I'm interested in Backend services, System Architecture & Design, Networking.",
  content2:
    "Proficient in C/C++ and .NET Core/.NET 5. I've got an experience with React Native, JavaScript & GoLang. Currently learning various technologies, concepts and frameworks. ",          
    
};

const progressData = [
  {
    id: 1,
    title: "Development",
    percantage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "System Design",
    percantage: 60,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Debugging",
    percantage: 95,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Useless Repo Deleted on GitHub",
    count: 532,
    icon: "fab fa-github",
  },
  {
    id: 2,
    title: "Cups of coffee, sorry I mean Nescafe",
    count: 5670,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Minutes Spent to Debug a Stupid Mistake",
    count: 45000,
    icon: "icon-clock",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} style={{ borderRadius: "50%", height: "12em"}} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <br />
                  <p> {aboutContent.content2} </p>
                  <div className="mt-3">
                    <a download="Kaan Taze CV" href="https://drive.google.com/uc?export=download&id=1Wramvo8x9YZSj_LmdAE0Yhg3Zy0Ey2TB" className="btn btn-default">
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div style={{justifyContent: "center"}} className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
