import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";
import { db } from "../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

function Bloglist() {
  document.body.classList.add("dark");

  // const categories = ["Software", "Life"];

  const [fetchedCategories, setFetchedCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [loading, setLoading] = useState(true);



  // Fetch categories
  const fetchCategories = async () => {
    try {
      const categoriesSnapshot = await getDocs(collection(db, "tech.categories"));
      const categoriesArray = categoriesSnapshot.docs.map(doc => doc.data().name);
      setFetchedCategories(categoriesArray);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);  // Set loading to false when done fetching
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsQuery = query(collection(db, "tech.blogPosts"), orderBy("date", "desc"));
        const blogsSnapshot = await getDocs(blogsQuery);
        const blogsArray = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(blogsArray);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchCategories();
    fetchBlogs();
  }, []);

  const filteredBlogs = selectedCategory
    ? posts.filter(blog => blog.category === selectedCategory)
    : posts;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredBlogs.slice(indexOfFirstPost, indexOfLastPost);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
      <div style={{ textAlign: "center", marginTop: "100px" }}>
                {loading ? (
                    <p>Loading...</p>
                ) : fetchedCategories.length === 0 ? (
                    <p>No Categories Available</p>
                ) : (
                    <>
                        <button className="btn btn-default" style={{ marginRight: "10px" }} onClick={() => setSelectedCategory(null)}>All</button>
                        {fetchedCategories.map(category => (
                            <button className="btn btn-default" style={{ marginRight: "10px" }} key={category} onClick={() => setSelectedCategory(category)}>
                                {category}
                            </button>
                        ))}
                    </>
                )}
            </div>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="row blog-wrapper fix-spacing">
              {currentPosts.length === 0 ? (
                <div className="col-12" style={{ textAlign: "center" }}>
                  <p>No Blog Posts Available</p>
                </div>
              ) : (
                currentPosts.map((blogItem) => (
                  <div className="col-md-6" key={blogItem.id}>
                    <Blog blogData={blogItem} />
                  </div>
                ))
              )}
            </div>

            <div className="spacer" data-height="50"></div>
            {!(filteredBlogs.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={filteredBlogs.length}
                paginate={(e, pageNumber) => {
                  e.preventDefault();
                  setCurrentPage(pageNumber);
                }}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Bloglist;
